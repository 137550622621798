import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20230222-Rate-Sheet.pdf";
const date = "22.02.2023";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>

    <br />
    <p>With effect from Wednesday 22 February 2023 we will be making the following changes to our range:</p>
    <p>
      <strong>Summary of changes</strong>
    </p>

    <p><strong>UK Residential Existing Customer Switching Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80% and 85% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 90% and 95% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
    </ul>

    <p><strong>UK Residential Existing Customer Borrowing More Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80% and 85% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
    </ul>

    <p><strong>UK Residential First Time Buyer Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 70%, 75%, 90% and 95% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
    </ul>

    <p><strong>UK Residential Home Mover Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 70%, 75%, 90% and 95% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
    </ul>

    <p><strong>UK Residential Remortgage Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
    </ul>

    <p><strong>UK Buy to Let Existing Customer Switching Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60% and 65% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60% and 65% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
    </ul>

    <p><strong>UK Buy to Let Existing Customer Borrowing More Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60% and 65% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60% and 65% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
    </ul>

    <p><strong>UK Buy to Let Purchase Range</strong></p>
    <ul>
      <li>5 Year Fixed Fee Saver at 60% LTV has reduced</li>
      <li>5 Year Fixed Standard at 65% and 75% LTV have reduced</li>
    </ul>

    <p><strong>UK Buy to Let Remortgage Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60% and 65% LTV have reduced</li>
      <li>2 Year Fixed Standard at 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
    </ul>

    <p><strong>International Residential Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 70% and 75% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 70% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70% and 75% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70% and 75% LTV have reduced</li>
    </ul>

    <p>We have introduced a 2 Year Tracker Fee Saver offering for our UK Residential Existing Customers Switching at 95% LTV.</p>
    <p>We have introduced a 10 Year Fixed Rate range, including both Standard and Fee Saver pricing packages, for our UK Residential Existing Customers Switching and Borrowing More and Remortgaging at 60%, 70%, 75% and 80% LTV.&nbsp; These are also available for our International Residential Existing and New Business Customers from 60% to 75% LTV.</p>
    <p>We have re-introduced a 3 Year Fixed Rate range, including both Standard and Fee Saver pricing packages, for our UK Residential First Time Buyer, Remortgage and Home Mover customers. These are also available for our International Residential Existing and New Business Customers from 60% to 75% LTV.</p>
    <p>A selection of the Fee Saver rates within the UK First Time Buyer range comes with a cashback incentive of &pound;250, &pound;500 or &pound;750 and the UK Home Mover range with &pound;250, &pound;350 or &pound;500.</p>
    <p>We have removed from sale our Two Year Fixed Rate Fee Saver offering at 95% LTV for UK Residential Home Mover and First Time Buyer customers until further notice.</p>
    <p>There are no changes to any other interest rates at this time.</p>
    <p>An updated mortgage rate sheet will be published on Wednesday 22 February to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
